<template>
  <div>
    <el-dialog
      title=""
      width="50%"
      :visible.sync="dialogShare"
      :before-close="closeShare"
      append-to-body
    >
      <!--      共享文件-->
      <div slot="title" class="dialog-title">
        {{ $t("vue_label_file_share_files") }} {{ fileName }}
      </div>
      <div class="share-content">
        <div class="share-content-top">
          <div class="sc-top-left">
            <!--            共享者 label.file.sharewith-->
            <div class="sc-top-left-tip">{{ $t("label.file.sharewith") }}</div>
            <div style="display: flex">
              <div class="select-role-type">
                <el-select
                  style="width: 60px"
                  v-model="checkedType"
                  @change="handleChangeType"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <svg
                      style="height: 20px; width: 20px; margin-right: 10px"
                      aria-hidden="true"
                    >
                      <use
                        :href="
                          item.value === 'person'
                            ? '#icon-guanzhuzhe'
                            : '#icon-group'
                        "
                      ></use>
                    </svg>
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
                <svg class="icon show-checked-type" aria-hidden="true">
                  <use
                    :href="
                      checkedType === 'person'
                        ? '#icon-guanzhuzhe'
                        : '#icon-group'
                    "
                  ></use>
                </svg>
              </div>
              <!--              请输入关键词-->
              <el-select
                ref="selA"
                v-model="value"
                multiple
                filterable
                remote
                reserve-keyword
                :placeholder="$t('label.searchkey')"
                :remote-method="remoteMethod"
                @change="handleChangeList"
                @focus="handleFocusList"
                popper-class="select-down"
                :popper-append-to-body="false"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sc-top-right">
            <!--            权限-->
            <div class="sc-top-right-tip">{{ $t("label.limit") }}</div>
            <!--            请选择 label.select.please-->
            <el-select
              v-model="selectValue"
              :placeholder="$t('label.select.please')"
            >
              <el-option
                v-for="item in selectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="share-content-center">
          <div class="sc-center-banner">
            <span class="open-icon" @click="openOrClose">
              <i :class="icons"></i>
              <!--            谁可以访问 label.file.who.can.access--> </span
            >{{ $t("label.file.who.can.access") }}
          </div>
          <div v-show="showMore">
            <div class="sc-center-list" v-loading="tableLoading">
              <div
                class="sc-item"
                v-for="item in currentRoleList"
                :key="item.id"
              >
                <div class="sc-item-left">
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="height: 20px; width: 20px"
                  >
                    <use
                      :href="
                        item.share_group === 'group'
                          ? allIcon['group']
                          : allIcon['followList']
                      "
                    ></use>
                  </svg>
                  <span class="sc-item-left-name">{{ item.name }}</span>
                </div>
                <div class="sc-item-right">
                  <!--                  所有人-->
                  <el-select
                    v-if="item.share_role === 'owner'"
                    class="sc-item-right-select"
                    size="mini"
                    :disabled="item.share_role === 'owner'"
                    :value="$t('label.file.owner')"
                  >
                    <el-option
                      v-for="itemB in selectOptions"
                      :key="itemB.value"
                      :label="itemB.label"
                      :value="itemB.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    @change="changeRolePem(item)"
                    v-if="item.share_role !== 'owner'"
                    class="sc-item-right-select"
                    size="mini"
                    v-model="item.share_role"
                  >
                    <el-option
                      v-for="itemB in selectOptions"
                      :key="itemB.value"
                      :label="itemB.label"
                      :value="itemB.value"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="sc-item-right-close"
                    @click="delSingleItem(item)"
                  >
                    <i
                      v-show="item.share_role !== 'owner'"
                      class="el-icon-close"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <!--            公共链接共享 label.file.public.link.share-->
            <div v-if="false" class="sc-center-banner">
              {{ $t("label.file.public.link.share") }}
            </div>
            <div v-if="false" class="sc-public-link">
              <div class="share-link">
                <!--                任何拥有该链接的人员均可查看与下载文件-->
                <p class="public-link-tip">
                  {{ $t("label.file.anyone.can.link") }}
                </p>
                <div class="create-public-link" v-show="!isCreatedLink">
                  <!--                  未创建公用链接 label.file.no.public.link.created-->
                  <div class="create-public-link-l not-create-link">
                    {{ $t("label.file.no.public.link.created") }}
                  </div>
                  <!--                  创建链接-->
                  <div class="create-public-link-r" @click="createLink()">
                    {{ $t("label.file.create.link") }}
                  </div>
                </div>
                <div class="create-public-link" v-show="isCreatedLink">
                  <el-input
                    disabled
                    v-model="publicLink"
                    class="create-public-link-l"
                  ></el-input>
                  <!--                  复制链接-->
                  <div
                    class="create-public-link-r"
                    @click="copyText(publicLink)"
                  >
                    {{ $t("label.file.copy.link") }}
                  </div>
                  <svg
                    class="icon public-link-delete"
                    aria-hidden="true"
                    v-if="shareData.createPublicLink"
                    @click="deleteLink()"
                  >
                    <use href="#icon-delete"></use>
                  </svg>
                </div>
              </div>
            </div>
            <!--            所有人设置 label.file.owner.settings-->
            <div class="sc-center-banner" v-if="shareData.editCannotShare">
              {{ $t("label.file.owner.settings") }}
            </div>
            <div class="sc-owner-setting" v-if="shareData.editCannotShare">
              <!--              防止其他人员共享和取消共享 label.file.prevent.share.operate-->
              <el-checkbox v-model="cannotShare" @change="cannotShareFn">{{
                $t("label.file.prevent.share.operate")
              }}</el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--        取 消-->
        <el-button v-loading="saveLoading" @click="closeShare">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确 定-->
        <el-button
          type="primary"
          v-loading="saveLoading"
          @click="comfirmAddRole"
          >{{ $t("label.confirm") }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="!" :visible.sync="beforeCreateLink" width="40%">
      <div slot="title" class="header-title">
        <div class="header-title-tip">!</div>
        <!--        创建不受限制的公用链接-->
        <span>{{ $t("label.file.public.link.share") }}</span>
      </div>
      <!--      任何拥有该链接的人员均可查看与下载文件。请确认文件不包含敏感信息。请考虑与特定用户或记录共享文件-->
      <div style="padding: 36px">
        <span
          >{{ $t("label.file.anyone.can.link") }}。{{
            $t("vue_label_file_safe_type_file")
          }}</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="beforeCreateLink = false">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确定-->
        <el-button type="primary" @click="confirmCreateLink">{{
          $t("label.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!--    删除公用链接-->
    <el-dialog
      width="40%"
      :title="$t('label.file.delete.public.link')"
      :visible.sync="innerVisible"
    >
      <!--      删除公用链接后，该地址将作废。重新创建公用链接将生成不同的地址。-->
      <div style="padding: 36px">
        <span>{{ $t("label.file.delete.public.link.desc") }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="innerVisible = false">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确认-->
        <el-button type="primary" @click="confirmDeleteLink">{{
          $t("label.confirm")
        }}</el-button>
      </div>
    </el-dialog>
    <!--    取消共享 label.file.cancel.sharing-->
    <el-dialog
      :title="$t('label.file.cancel.sharing')"
      :visible.sync="dialogDelete"
      width="30%"
    >
      <div style="padding: 36px">
        <!--        取消对成员-->
        <span>{{ $t("label.sure.delete") + " " + deleteItem.name }}！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--        取 消-->
        <el-button @click="dialogDelete = false">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确 定-->
        <el-button type="primary" @click="comfirmDeleteItem">{{
          $t("label.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { copyText } from "@/utils/copyInfo";
import * as fileApi from "./api.js";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";

export default {
  name: "FileShareRole",
  props: {
    isFromDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileId: "",
      fileName: "",
      shareData: {},
      options: [],
      value: [],
      tableLoading: false,
      saveLoading: false,
      loading: false,
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      icons: "el-icon-arrow-up",
      showMore: false,
      beforeCreateLink: false, // 未创建链接提示
      outerVisible: false, // 公共连接外
      isCreatedLink: false, // 是否创建公共链接
      innerVisible: false, // 公共连接内
      publicLink: "", // 链接示例
      cannotShare: false,
      dialogShare: false,
      roleConfig: {
        // 'owner': '所有人',
        // 'check': '只读',
        // 'cooperation': '读写',
        owner: this.$i18n.t("label.file.owner"),
        check: this.$i18n.t("label.readonly"),
        cooperation: this.$i18n.t("label.readwrite"),
      },
      selectOptions: [
        { value: "check", label: this.$i18n.t("label.readonly") },
        { value: "cooperation", label: this.$i18n.t("label.readwrite") },
      ],
      typeOptions: [
        {
          value: "person",
          // 用户 label.user
          label: this.$i18n.t("label.user"),
        },
        {
          value: "group",
          // 小组
          label: this.$i18n.t("label.ccchat.group.section"),
        },
      ],
      selectValue: "check",
      checkedType: "person",
      currentRoleList: [],
      deleteItem: {},
      dialogDelete: false,
    };
  },
  methods: {
    copyText,
    cannotShareFn() {
      fileApi
        .cannotShareInfo({
          id: this.fileId,
          value: this.cannotShare.toString(),
        })
        .then(() => {});
    },
    closeShare() {
      this.dialogShare = false;
      this.isCreatedLink = false;
      this.cannotShare = false;
      this.showMore = false;
      this.icons = "el-icon-arrow-up";
      this.value = [];
      this.options = [];
      this.currentRoleList = [];
      this.publicLink = "";
      this.selectValue = "check";
      this.shareData = {};
      this.checkedType = "person";
    },
    handleChangeType() {
      this.options = [];
    },
    handleChangeList() {
      this.currentRoleList.forEach((item) => {
        this.value.forEach((itemValue, indexValue) => {
          if (item.share_to === itemValue) {
            this.value.splice(indexValue, 1);
            // 已对其设置过共享！ label.file.share.select.warning
            this.$message.warning(
              this.$i18n.t("label.file.share.select.warning")
            );
          }
        });
      });
    },
    // 收起关闭
    openOrClose() {
      this.icons =
        this.icons === "el-icon-arrow-down"
          ? "el-icon-arrow-up"
          : "el-icon-arrow-down";
      this.showMore = this.icons === "el-icon-arrow-down" ? true : false;
    },
    createLink() {
      if (this.shareData.createPublicLink) {
        this.beforeCreateLink = true;
      } else {
        // 您暂时没有权限创建公用链接，请联系管理员
        this.$message.warning(this.$i18n.t("message.nopermession"));
      }
    },
    copyLink() {
      // 已复制该公用链接 label.file.copy.public.link
      this.$message({
        showClose: true,
        type: "success",
        message: this.$i18n.t("label.file.copy.public.link"),
      });
    },
    deleteLink() {
      this.innerVisible = true;
    },
    confirmCreateLink() {
      // 请求创建连接接口，给publicLink赋值
      fileApi.createPublicLink({ id: this.fileId }).then(() => {
        this.beforeCreateLink = false;
        this.outerVisible = true;
        this.isCreatedLink = true;
        fileApi
          .getPublicLink({
            id: this.fileId,
          })
          .then((res) => {
            this.publicLink = res.data.publicLink;
          });
      });
    },
    confirmDeleteLink() {
      // 请求删除连接接口，给publicLink置空
      fileApi.delPublicLink({ id: this.fileId }).then(() => {
        this.isCreatedLink = false;
        this.innerVisible = false;
        this.publicLink = "";
      });
    },
    openLinkDialog() {
      fileApi
        .getPublicLink({
          id: this.fileId,
        })
        .then((res) => {
          if (res.data.publicLink) {
            this.publicLink = res.data.publicLink;
            this.outerVisible = true;
            this.isCreatedLink = true;
          } else {
            this.outerVisible = true;
            this.isCreatedLink = false;
          }
        });
    },
    // 获取焦点请求列表
    handleFocusList() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let option = {
          fileId: this.libId,
          type: this.checkedType,
          keyword: this.searchQuery,
        };
        fileApi
          .getShareRoleList(option)
          .then((res) => {
            this.options = res.data;
          })
      }, 200);
    },
    // 打开搜索列表
    searchRolesList() {
      this.remoteMethod();
    },
    // 搜索列表
    remoteMethod(query) {
      this.searchQuery = query;
      let option = {
        fileId: this.libId,
        type: this.checkedType,
        keyword: this.searchQuery,
      };
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        fileApi
          .getShareRoleList(option)
          .then((res) => {
            this.options = res.data;
          })
      }, 200);
    },
    // 打开弹窗
    open(id, name) {
      this.fileId = id;
      this.fileName = name;
      this.dialogShare = true;
      this.getRoles();
    },
    // 获取已选列表
    getRoles() {
      this.tableLoading = true;
      fileApi
        .getShareList({
          id: this.fileId,
        })
        .then((res) => {
          this.shareData = res.data;
          if (res.data.cannotshare === "true") {
            this.cannotShare = true;
          } else if (res.data.cannotshare === "false") {
            this.cannotShare = false;
          }
          if (res.data.publicLink) {
            this.publicLink = res.data.publicLink;
            this.isCreatedLink = true;
          }
          this.currentRoleList = res.data.shareList;
          this.tableLoading = false;
        });
    },
    // 打开删除单个成员
    delSingleItem(obj) {
      this.dialogDelete = true;
      this.deleteItem = obj;
    },
    // 删除单个成员
    comfirmDeleteItem() {
      fileApi.cancelShareInfo({ id: this.deleteItem.id }).then(() => {
        this.getRoles();
        this.dialogDelete = false;
        if (this.isFromDetail) {
          this.$emit("freshDetail", this.fileId);
        }
      });
    },
    // 恒该单个成员权限
    changeRolePem(obj) {
      let ob = {
        id: obj.share_to,
        shareType: obj.share_group,
        shareRoleType: obj.share_role,
      };
      let op = {
        id: this.fileId,
        value: JSON.stringify([ob]),
      };
      fileApi.saveShareInfo(op).then(() => {
        // 更改成功
        this.$message.success(this.$i18n.t("vue_label_notice_change_success"));
        this.getRoles();
      });
    },
    // 确认保存
    comfirmAddRole() {
      if (this.value.length === 0) {
        // 请至少选择一个成员 label.campaigns.memberalert
        this.$message.warning(this.$i18n.t("label.campaigns.memberalert"));
        return false;
      }
      let obList = [];
      this.saveLoading = true;
      this.value.map((item) => {
        if (item.substring(0, 3) === "005") {
          obList.push({
            id: item,
            shareType: "person",
            shareRoleType: this.selectValue,
          });
        } else {
          obList.push({
            id: item,
            shareType: "group",
            shareRoleType: this.selectValue,
          });
        }
      });
      let op = {
        id: this.fileId,
        value: JSON.stringify(obList),
      };
      fileApi.saveShareInfo(op).then(() => {
        this.getRoles();
        this.$message.success(this.$i18n.t("label.file.share.file.success"));
        this.saveLoading = false;
        if (this.isFromDetail) {
          this.$emit("freshDetail", this.fileId);
        }
        this.closeShare();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tag.el-tag--info {
  display: flex;
  align-items: center;
}
::v-deep .el-select__tags-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
::v-deep .select-down {
  min-width: 100px !important;
  max-width: 200px !important;
}
::v-deep .select-down .el-select-dropdown__item {
  width: 200px !important;
}
::v-deep .el-input__suffix-inner .el-icon- {
  transform: rotate(0deg);
}
::v-deep .el-icon-:before {
  content: "\e778";
}
::v-deep .el-dialog__body {
  padding: 16px 0px;
  overflow: visible;
}
::v-deep .el-select {
  width: 100%;
}
.open-icon {
  margin-right: 10px;
  cursor: pointer;
}
.header-title {
  display: flex;
  align-items: center;

  .header-title-tip {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #888888;
    color: #fffdef;
    margin-right: 5px;
    font-size: 10px;
  }
}

.public-link-tip {
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
}

.create-public-link {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  margin-bottom: 10px;

  .create-public-link-l {
    flex-grow: 1;
    display: flex;
    align-items: center;
    //padding: 0 10px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #dddbda;
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
  }
  .not-create-link {
    padding: 0 10px;
    box-sizing: border-box;
    color: #999999;
    font-size: 12px;
  }
  .create-public-link-r {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #006dcc;
    width: 80px;
    height: 32px;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    cursor: pointer;
  }

  .public-link-delete {
    width: 26px;
    height: 26px;
    margin: 0 10px;
    cursor: pointer;
  }
}

.share-content {
  width: 100%;
  .share-content-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 36px;
    box-sizing: border-box;
    margin-bottom: 11px;
    .sc-top-left {
      width: 55%;
      .sc-top-left-tip {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
    .sc-top-right {
      width: 35%;
      .sc-top-right-tip {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
  }
  .share-content-center {
    width: 100%;
    .sc-center-banner {
      padding: 0 36px;
      box-sizing: border-box;
      background: #f3f2f2;
      height: 30px;
      line-height: 30px;
      width: 100%;
      margin-bottom: 11px;
    }
    .sc-center-list {
      margin: 0 36px;
      border-top: 1px solid #dedcda;
      height: 122px;
      overflow-y: auto;
      margin-bottom: 11px;
      .sc-item {
        height: 40px;
        border-bottom: 1px solid #dedcda;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sc-item-left {
          width: 60%;
          display: flex;
          align-items: center;
          .sc-item-left-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          .sc-item-left-name {
            line-height: 42px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .sc-item-right {
          width: 35%;
          display: flex;
          align-items: center;
          .sc-item-right-select {
          }
          .sc-item-right-close {
            margin-right: 5px;
            width: 14px;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .sc-owner-setting,
    .sc-public-link {
      padding: 0 36px;
      box-sizing: border-box;
    }
  }
}
.select-role-type {
  position: relative;
  .show-checked-type {
    position: absolute;
    left: 12px;
    top: 7px;
    width: 25px;
    height: 25px;
  }
}
.select-list {
  position: relative;
  .select-list-search {
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
