<template>
  <!-- 文件预览下的预览组件，也作为全局文件预览组件 -->
  <div class="preview_body" v-if="show" :style="{ zIndex: zIndex + 100 }">
    <!-- 预览上下文盒子 -->
    <div class="preview_body_context">
      <!-- 顶部header -->
      <HeaderBar
        :showData="currentActiveData"
        @closePreview="closePreview"
        @fileDeal="fileDeal"
        :showBtn="showBtn"
        :hideBtn="hideBtn"
        class="p_head"
      />

      <!-- 预览内容展示 -->
      <div class="preview_content" v-loading="wordShowLoading">
        <!-- 左操作按钮 -->
        <div class="left_btn_bar" @click.stop v-if="isShowLeftBtn">
          <div class="wrapper_tray" @click="leftBtnClick">
            <i class="left_btn"></i>
          </div>
        </div>
        <!-- 右操作按钮 -->
        <div class="right_btn_bar" @click.stop v-if="isShowRightBtn">
          <div class="wrapper_tray" @click="rightClick">
            <i class="right_btn"></i>
          </div>
        </div>

        <!-- 预览渲染区域 -->
        <div class="preview_content_inner" :style="isAddOverFlow">
          <!-- 原生图片预览地址 -->
          <NativePreview
            v-if="previewType === 'useNative'"
            :nativeData="currentActiveData"
            @loadSuccess="NativePreviewLoaded"
            :isLightningOriginal="isLightningOriginal"
          />
          <!-- 使用付费插件预览资源 -->
          <Plugin-Preview
            v-else-if="previewType === 'usePlugin'"
            :showData="currentActiveData"
            @setnNotCanPreviewFn="setnNotCanPreviewFn"
            @loadSuccess="pluginPreviewLoaded"
          />
          <!-- 不支持预览时 -->
          <NotSupportAlert v-else-if="previewType === 'notSupport'" />
        </div>
      </div>

      <!-- 底部缩略图 bar -->
      <BottomBar
        :currentFocusIndex="currentFocusIndex"
        nativeImgPath="nativeImgPath"
        @imgItemClick="imgItemClick"
        v-if="isMultiplePreview"
        :bottomBarData="showData"
        class="p_bottom"
      />
    </div>

    <!-- 分享 -->
    <fileShareRole ref="isShare" />
    <!-- 删除弹窗 -->
    <DeleteDialog
      :dialogDelete.sync="dialogDelete"
      :showData="currentActiveData"
      @deleteSuccess="deleteSuccess"
      :id="id"
      v-if="dialogDelete"
    />

    <!-- 编辑文件 -->
    <EditDialog
      :id="id"
      :dialogFileEdit.sync="dialogFileEdit"
      :showData="currentActiveData"
      v-if="dialogFileEdit"
      @editSaveSuccess="editSaveSuccess"
    />
  </div>
</template>

<script>
/**
 * @description 文件预览弹窗
 * 功能：
 * 1、文件预览
 * 逻辑：文件预览
 * 1、根据文件类型展示不同图标、文件可操作的操作按钮，以及文件预览，以及文件不能预览时的缺省图和提示语
 */
import FileShareRole from "./FileShareRole";
import axios from "@/config/httpConfig";
export default {
  created() {
    // 初始化配置
    this.initConfig();
  },
  components: {
    FileShareRole,
    DeleteDialog: () => import("./DeleteDialog.vue"),
    EditDialog: () => import("./EditDialog.vue"),
    BottomBar: () => import("./BottomBar.vue"),
    HeaderBar: () => import("./HeaderBar.vue"),
    NotSupportAlert: () => import("./NotSupportAlert.vue"),
    PluginPreview: () => import("./PluginPreview.vue"),
    NativePreview: () => import("./NativePreview.vue"),
  },
  props: {
    /**
     *  TOFO 抛出的显示隐藏属性
     *  显示与隐藏
     */
    show: {
      type: Boolean,
      default: false,
    },
    // 文件对象列表做的处理，其他地方可以不用传
    fromList: {
      type: Boolean,
      default: false,
    },
    // 展示的表头数据
    /**
     *  default: {
        类型
        type: 'jpg',
        name: '示例名称',
        lastmodifydate: '示例时间',
        是否使用原生 img 标签传入src链接渲染,而不使用插件进行预览
        isUseNativePreview: false,
        // 预览图片多张
        nativePreviewList:[
          {
            path:""
          }
        ]
      }
     */
    showData: {
      type: [Array, Object],
      // 示例数据
      require: true,
    },
    /**
     * 配置显示的按钮
     */
    configButtm: {
      type: Array,
      default: () => [],
    },
    /**
     * 是否是 曹文一的 lightning公式图片的图片
     * 如果是lightning的公式图片预览就需要单独调用曹文一的接口进行预览
     * 预览地址和旧版的预览地址不一样
     */
    isLightningOriginal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 当前预览的索引
      currentFocusIndex: 0,
      zIndex: this.$store.state.home.zIndex,
      dialogDelete: false,
      wordShowLoading: false, // doc/docx文件加载loading
      showBtn: [], // 展示的操作按钮集合
      hideBtn: [], // 隐藏的操作按钮集合
      // 当前操作的id
      id: "",
      // 文件编辑弹窗是否显示
      dialogFileEdit: false,
      // 预览类型
      previewType: "",
      /**
       * 是否多图片预览，
       * 多图片预览下面显示缩略图选择区域
       */
      isMultiplePreview: false,
      /**
       * 当前激活的对象
       * 单个/多个
       * 都是用此字段
       */
      currentActiveData: {},
    };
  },
  methods: {
    /**
     * 用于初始化配置
     * 当前组件初始化时调用
     */
    initConfig() {
      const configButtm = this.configButtm;
      // 配置的菜单大于1个才执行配置操作
      if (configButtm.length > 0) {
        this.handleBtn(configButtm);
      }
    },
    /**
     * 文件操作
     * @param {item} 文件操作类型
     */
    fileDeal(item) {
      if (item === "replace") {
        this.$emit("replaceFile", item);
        return;
      }
      if (this.currentActiveData.file_info_id) {
        this.id = this.currentActiveData.file_info_id;
      } else {
        this.id = this.currentActiveData.id;
      }
      switch (item) {
        case "viewinfo":
          this.goFileDetail();
          break;
        // 分享
        case "share":
          this.handleShare();
          break;
        // 下载
        case "download":
          this.downloadFile();
          break;
        // 删除
        case "delete":
          this.deleteItem();
          break;
        // 编辑信息
        case "editinfo":
          this.editInfo();
          break;
      }
    },
    // 编辑信息
    editInfo() {
      this.loading = true;
      this.dialogFileEdit = true;
    },
    // 删除文件
    deleteItem() {
      this.dialogDelete = true;
    },
    // 删除文件成功，讲事件发送给父组件调用刷新列表使用
    deleteSuccess() {
      this.$emit("deleteSuccess");
    },
    editSaveSuccess() {
      // 编辑保存成功事件 发射给父组件
      this.$emit("editSaveSuccess");
    },
    // 下载
    async downloadFile() {
      let baseURL = "";
      const { isUseNativePreview } = this.currentActiveData;
      const token = this.$CCDK.CCToken.getToken();
      const link = document.createElement("a");
      link.setAttribute("download", "");
      /**
       * 如果是附件中的预览想要下载，
       * 要和文件的预览下载分开用两个链接
       */
      if (isUseNativePreview) {
        /**
         * 如果是 lightning公式图片
         * 则需要专门调用曹文义写的接口进行下载和预览
         */
        if (this.isLightningOriginal) {
          /**
           * 这个地址先写死，地址配置和后台设置请求的地址不对应
           */
          axios
            .post(
              `${window.gw["DEV_CONSOLE"]}/devconsole/staticResource/detailStaticResource`,
              { id: this.id }
            )
            .then((res) => {
              const { data } = res;
              link.href = data?.cdnfileSource;
              link.click();
            });
        } else {
          /**
           * 否则就正常使用地址进行下载
           */
          baseURL = this.$cookies.get("domainName");
          link.href = `${this.$baseConfig.baseURL}/file/viewImg?id=${
            this.id
          }&accessToken=${this.$CCDK.CCToken.getToken()}`;
          link.click();
        }
      } else {
        // 否则默认使用文件菜单下的下载功能
        baseURL = this.$baseConfig.baseURL;
        link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${this.id}`;
        link.click();
      }
    },
    // 文件详情
    goFileDetail() {
      this.closePreview();
      this.$router.push({
        path: "/ccFileCenter/fileDetail",
        query: {
          id: this.id,
        },
      });
    },
    // 分享
    handleShare() {
      this.$refs.isShare.open(this.id, "obj.name");
    },
    /**
     * 取消文件预览
     */
    closePreview() {
      this.viewUrl = "";
      this.$emit("closePreview");
    },
    // 设置按钮
    handleBtn(btns) {
      var btnArr = [];
      btns.map((item) => {
        if (
          item !== "openin" &&
          item !== "unfollow" &&
          item !== "following" &&
          item !== "publicLink"
        ) {
          btnArr.push(item);
        }
        if (item === "editinfo" && !this.fromList) {
          btnArr.push("replace");
        }
      });
      // 超出3个按钮，显示在更多菜单中
      if (btnArr.length <= 3) {
        this.showBtn = btnArr;
        this.hideBtn = [];
      } else {
        this.showBtn = btnArr.slice(0, 3);
        this.hideBtn = btnArr.slice(3);
      }
    },
    /**
     * 对文件进行处理并预览
     */
    async handleView() {
      const { isUseNativePreview } = this.currentActiveData;

      // 默认使用插件预览-> 向下兼容
      if (isUseNativePreview) {
        this.previewType = "useNative";
      } else {
        this.previewType = "usePlugin";
      }
    },
    // 插件预览加载完成
    pluginPreviewLoaded() {
      this.wordShowLoading = false;
    },
    NativePreviewLoaded() {
      this.wordShowLoading = false;
    },
    // 不能预览时设置状态方法
    setnNotCanPreviewFn() {
      this.wordShowLoading = false;
      this.previewType = "notSupport";
    },
    // 缩略图item点击
    imgItemClick({ index }) {
      this.currentFocusIndex = index;
    },
    // 左侧按钮点击
    leftBtnClick() {
      if (this.currentFocusIndex < 1) {
        this.currentFocusIndex = 0;
      } else {
        this.currentFocusIndex--;
      }
    },
    // 右侧按钮点击
    rightClick() {
      if (this.currentFocusIndex >= this.showData.length - 1) {
        this.currentFocusIndex = this.showData.length - 1;
      } else {
        this.currentFocusIndex++;
      }
    },
    /**
     * 是否是多图片预览检测
     * 设置默认选中的数据
     *  */
    multiplePreviewCheck() {
      let data = [];
      let showData = this.showData;
      let activeData = null;
      // 向下兼容对象数据格式
      if (Array.isArray(showData)) {
        data = showData;
      } else if (
        Object.prototype.toString.call(showData) === "[object Object]"
      ) {
        data.push(showData);
      }

      // 是否是多图片预览
      if (data.length > 1) {
        this.isMultiplePreview = true;
        // 设置默认选中的数据
        activeData = data.find((item, index) => {
          // 设置默认选中的缩略图
          this.currentFocusIndex = index;
          return item.active === true;
        });
        // 如果没有默认选中的数据，则默认选中首个
        if (!activeData) {
          activeData = data[0];
          // 设置当前选中的索引
          this.currentFocusIndex = 0;
        }
      } else {
        this.isMultiplePreview = false;
        activeData = data[0];
      }
      // 设置当前选中的数据
      this.currentActiveData = activeData;
    },
  },
  computed: {
    isAddOverFlow() {
      if (this.showData.isUseNativePreview) return { overflow: "auto" };
      return false;
    },
    // 是否显示左侧切换按钮
    isShowLeftBtn() {
      if (this.isMultiplePreview) {
        if (this.currentFocusIndex < 1) return false;
        return true;
      }
      return false;
    },
    // 是否显示右侧切换按钮
    isShowRightBtn() {
      if (this.isMultiplePreview) {
        if (this.currentFocusIndex >= this.showData.length - 1) return false;
        return true;
      }
      return false;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.wordShowLoading = true;
          this.multiplePreviewCheck();
          this.handleView();
        }
      },
    },
    // 监听索引改变，指向对应的要预览的图片
    currentFocusIndex(nval) {
      this.currentActiveData = this.showData[nval];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
