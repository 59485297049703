import axios from "@/config/httpConfig";
// 文件预览接口第三方集成检测
export const preview=v=>axios.post("/file/preview",v)

// 禁止共享
export function cannotShareInfo(args) {
    return axios.post('file/cannotShare', args)
}

// 创建公用链接
export function createPublicLink(args) {
    return axios.post('file/createPublicLink', args)
}

// 获取公用链接
export function getPublicLink(args) {
    return axios.post('file/publicLink', args)
}

// 删除公用链接
export function delPublicLink(args) {
    return axios.post('file/deletePublicLink', args)
}

// 文件获取共享角色
export function getShareRoleList(args) {
    return axios.post('file/getShareRole', args)
}


// 获取文件共享信息
export function getShareList(args) {
    return axios.post('file/queryShareInfo', args)
}

// 取消共享
export function cancelShareInfo(args) {
    return axios.post('file/cancelShare', args)
}

// 保存文件共享信息
export function saveShareInfo(args) {
    return axios.post('file/saveShare', args)
}


// 文件删除
export function deleteFile(args) {
    return axios.post('file/delete', args)
}


// 文件编辑回显
export function editFile(args) {
    return axios.post('file/edit', args)
}

// 文件保存编辑
export function saveEditFile(args) {
    return axios.post('file/saveEdit', args)
}